body,
html,
.wrapper {
  width: 100%;
  height: 1%;
}
.wrapper {
  position: relative;
  background: #e7f0f7;
}
.progressbar {
  display: block;
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 10px;
}
.progressbar::before,
.progressbar::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-image: linear-gradient(
    90deg,
    #e91e63 0%,
    #e91e63 20%,
    #03a9f4 20%,
    #03a9f4 40%,
    #8bc34a 40%,
    #8bc34a 60%,
    #ffeb3b 60%,
    #ffeb3b 80%,
    #ff5722 80%,
    #ff5722 100%
  );
  background-position: 0 0;
  background-repeat: repeat-x;
  animation: movebar 5s linear infinite;
}
.progressbar::before {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
}
.progressbar::after {
  z-index: 9;
  top: 6px;
  filter: blur(16px);
  opacity: 0.7;
}
.stylization {
  position: absolute;
  z-index: 999;
  height: 4px;
  width: 90%;
  left: 5%;
  top: 6px;
  opacity: 0.3;
}
.stylization::before,
.stylization::after {
  content: "";
  display: block;
  position: absolute;
  height: 4px;
  top: 0;
  border-radius: 2px;
}
.stylization::before {
  background: #fff;
  left: 0;
  right: 10px;
}
.stylization::after {
  width: 6px;
  background: #fff;
  right: 0;
}
@-moz-keyframes movebar {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 4000px 0;
  }
}
@-webkit-keyframes movebar {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 4000px 0;
  }
}
@-o-keyframes movebar {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 4000px 0;
  }
}
@keyframes movebar {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 4000px 0;
  }
}
@media screen and (max-width: 600px) {
  @keyframes movebar {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 1500px 0;
    }
  }
}
