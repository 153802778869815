line {
  margin: 10px;
  stroke-width: 30px;
  stroke-linecap: round;
  fill: transparent;
}

.box {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: lightgray;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media screen and (max-width: 768px) {
  .box {
    display: none;
  }
}

ull {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  border-radius: 25px;
}

lii {
  background-color: rgba(214, 214, 214, 0.5);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  cursor: pointer;
}

lii:last-child {
  margin-bottom: 0px;
}

.title {
  border-radius: 20px;
}

.row {
  width: 100%;
  height: 8px;
  background-color: #999;
  border-radius: 10px;
  margin-top: 12px;
}
